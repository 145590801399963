import React, { useState, useEffect } from "react";
import "@src/scss/App.scss";
import Seo from "@components/seo";
import Layout from "@components/layout";
import { graphql } from 'gatsby';
// import gql from "graphql-tag";
// import { useQuery } from '@apollo/client';
import ModuleSection from '@components/modules/moduleWrapper';
import BannerRender from "@components/modules/Banner/BannerRender";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import ReviewCard from "@components/reviews/ReviewCard";
import { Routes } from '@components/routes';
import BookValuation from '@components/modules/BookValuation';
import SocialShare from '@components/modules/SocialShare';
import TeamContact from '@components/modules/TeamContact';
import LoyaltyProgram from '@components/modules/Forms/LoyaltyProgram';
// import Loader from '@components/loader';

// const GET_ALL_REVIEWS = gql`
//     query GetReviwes {
//         reviews(publicationState: LIVE, sort: "Date:DESC") {
//             id
//             Text
//             Show_Multi_Country_Page
//             Show_Vacation_Home_Review_Page
//             Person
//             Date
//         }
//     }
// `;

const ReviewsLandingPages = (props) => {
    // const { loading, error, data } = useQuery(GET_ALL_REVIEWS);
    const { glstrapi: { page, reviews } } = props?.data
    const breadcrumbs = [
        { label: "About", url: `${Routes.AboutPage}` },
        { label: page?.Pagename, url: `${Routes.AboutPage}/${page?.Slug}` }
    ]

    const postsPerPage = 8;
    let arrayForHoldingPosts = [];
    const [postsToShow, setPostsToShow] = useState([]);
    const [next, setNext] = useState(8);

    const [filteredReviews, setFilteredReviews] = useState([]);
    useEffect(() => {
        if (reviews && reviews?.length > 0) {
          // Filter the Non-Vacation Home reviews
          const filtered = reviews.filter(review => (
            review.Show_Multi_Country_Page === false &&
            (review.Show_Vacation_Home_Review_Page === false || review.Show_Vacation_Home_Review_Page === null)
          ));
          setFilteredReviews(filtered);
        }
      }, [reviews]);

    const loopWithSlice = (start, end) => {
        const slicedPosts = filteredReviews.slice(0, end);
        if (filteredReviews) {
          arrayForHoldingPosts = [...arrayForHoldingPosts, ...slicedPosts];
          setPostsToShow(arrayForHoldingPosts);
        }
      };
      
    const handleShowMorePosts = () => {
        loopWithSlice(next, next + postsPerPage);
        setNext(next + postsPerPage);
    }

    useEffect(() => {
        loopWithSlice(0, postsPerPage);
        setNext(8)
    }, [filteredReviews])

    return (
        <Layout bodyClass="reviews-landing">
            <Seo
                title={page?.Metadata?.title ? page.Metadata.title : page.Title}
                description={page?.Metadata?.description ? page.Metadata.description : null}
                // image={page?.Metadata?.image?.url ? page.Metadata.image.url : null}
                image={page?.Metadata?.image?.url_sharp?.childImageSharp ? page.Metadata.image.url_sharp.childImageSharp.resize : null}
            />
            <BannerRender
                page={page}
                breadcrumbs={breadcrumbs}
            />
            <ModuleSection sectionSpacing="py-40 py-md-64 py-lg-80">
                <Row>
                    <Col xs={{ span: 12, order: 2 }} lg={{ span: 3, order: 1 }} xl={{ span: 2, order: 1 }}>
                        <Stack className="gap-40 gap-md-48 mt-40 mt-md-56 mt-lg-0 sidebar-float">
                            <TeamContact moduleData={{ removeContainer: true, sectionSpacing: ' ' }} />
                            <SocialShare />
                        </Stack>
                    </Col>
                    <Col xs={{ span: 12, order: 1 }} lg={{ span: 9, order: 2 }} xl={{ span: 9, offset: 1, order: 2 }}>
                        {/* <Loader loading={loading} error={error}> */}
                            <Stack className="gap-24 gap-md-32">
                                {postsToShow?.map((review, i) => {
                                    return(
                                        review.Show_Multi_Country_Page === false &&
                                        <React.Fragment key={review?.id}>
                                            <ReviewCard
                                                review={review}
                                                custom={i}
                                            />
                                            {((i + 1) % 4) === 0 &&
                                                <BookValuation
                                                    moduleData={{
                                                        Title: "Find out <strong>who’s who</strong> at Exclusive Links",
                                                        Text: "Contact a member of our team directly on phone or WhatsApp to discuss your property requirements."
                                                    }}
                                                    Buttons={[
                                                        {
                                                            id: "1",
                                                            btnClass: "btn-secondary",
                                                            label: "Meet our team",
                                                            // icon: "personIcon",
                                                            manualLink: `${Routes.AboutPage}${Routes.TeamsPage}`
                                                        }
                                                    ]}
                                                />
                                            }
                                        </React.Fragment>
                                    )
                                })}
                                {postsToShow?.length !== filteredReviews?.length &&
                                    <Button
                                        variant="loadMore"
                                        onClick={handleShowMorePosts}
                                        className="w-100"
                                    >
                                        Load more
                                    </Button>
                                }
                            </Stack>
                        {/* </Loader> */}
                    </Col>
                </Row>
            </ModuleSection>
            <LoyaltyProgram
                sectionClass="section-grey section-contact-form"
                title="Join our Loyalty Program"
                text="<p>We recognise the importance of repeat customers, and we would like to acknowledge your support to Exclusive Links and bring you incentives to continue referring your colleagues, associates, friends, and family to our team. Thank you for your business and please complete details below.</p>"
            />
        </Layout>
    );
}

export default ReviewsLandingPages

export const query = graphql`
query reviewsLandingQuery($id: ID!) {
    glstrapi {
        page(id: $id, publicationState: LIVE) {
            Pagename
            Title
            Slug
            HeaderTemplate
            BannerImage {
                ... on GLSTRAPI_ComponentModulesBannerImage {
                    ...ImgBtnNormalFragment
                }
            }
            Metadata {
                title
                description
                image {
                    url
                    url_sharp {
                        childImageSharp {
                            resize(width: 1200) {
                                src
                                height
                                width
                            }
                        }
                    }
                }
            }
        }
        reviews(publicationState: LIVE, sort: "Date:DESC") {
            id
            Text
            Show_Multi_Country_Page
            Show_Vacation_Home_Review_Page
            Person
            Date
        }
    }
}
`