import * as React from "react";
import Stack from "react-bootstrap/Stack";
import Button from "react-bootstrap/Button";
import ModuleSection from '@components/modules/moduleWrapper';
import { StarberryIcons } from "@components/icons/index";
import { onlyNumbers, uaenumberformat } from '@components/utils/CleanedHtml';
import ButtonLink from '@components/ButtonLink';
import Routes from '@components/routes';
import { ModalConsumer } from '@components/modal/ModalContext';
import { ContactModal } from '@components/modal/modals';
import { VactionHomesContactData } from "@components/common/site/constants";
import './teamContact.scss';

const VacationHomeContact = ({ moduleData }) => {
    return(
        <ModuleSection removeContainer={moduleData?.removeContainer ? moduleData.removeContainer : false} sectionSpacing={moduleData?.sectionSpacing} sectionClass={`module team-contact-module vacation-homes-contact-module ${moduleData?.moduleClass ? moduleData?.moduleClass : ''}`}>
            
                <Stack className="gap-24">
                    <Stack direction="horizontal" className="gap-16 align-items-start flex-lg-column">
                        <img src={VactionHomesContactData?.LogoImage?.url} alt={VactionHomesContactData.LogoImage.alternativeText} loading='lazy' className="vacation-home-logo" />
                        <Stack>
                            <Stack className="gap-4 team-contact-name">
                                <span className="team-contact-title">Contact Us</span>
                                <span className="team-contact-text">We’re here to help.</span>
                            </Stack>
                            <Stack className="gap-8 gap-md-40 gap-lg-8 mt-16 mt-md-24 team-contact-btns flex-md-row flex-lg-column align-items-start">
                                <ButtonLink manualLink={`https://wa.me/${onlyNumbers(VactionHomesContactData?.WhatsApp)}`} label={uaenumberformat(VactionHomesContactData?.WhatsApp)} icon="whatsAppIcon" variant="link-gray" className="whatsApp" />
                                <ButtonLink manualLink={`tel:${uaenumberformat(VactionHomesContactData?.Phone)}`} label={uaenumberformat(VactionHomesContactData?.Phone)} icon="phoneIcon" variant="link-gray" />
                                <ModalConsumer>
                                    {({ showModal }) => (
                                        <Button variant="link-gray" className="email_us" onClick={() => showModal(ContactModal, { show: true })}><StarberryIcons iconName="emailIcon" className="bi" /><span>Email Us</span></Button>
                                    )}
                                </ModalConsumer>
                                
                                <ButtonLink btnClass="mt-8 mt-md-0 mt-lg-16 team-contact-view-more" manualLink={`${Routes.ContactUsPage}`} variant="link-primary-underlined">View Our Offices</ButtonLink>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
        </ModuleSection>
    )
}

export default VacationHomeContact