import React from 'react';
import { graphql } from "gatsby";
import SocialShare from '@components/socialShare';

const SocialShareModule = ({ moduleData }) => {
    return(
        <SocialShare
            type="normal"
            shareLabel={moduleData?.Title ? moduleData.Title : "Share this Page"}
            hideShareIcon={false}
        />
    )
}

export default SocialShareModule

export const socialShareFragment = graphql`
    fragment SocialShareFragment on GLSTRAPI_ComponentModulesSocialShare {
        id
        moduleType
        moduleData {
            Text
            Title
            moduleClass
            sectionSpacing
        }
    }
`